import { Output, EventEmitter } from '@angular/core';

export abstract class ModalComponent<T> {

  @Output()
  public onClose: EventEmitter<T> = new EventEmitter();

  @Output()
  public onDismiss: EventEmitter<any> = new EventEmitter();

  public setOptions(options: any): void { }

  public close(result?: T): void {
    this.onClose.emit(result);
  }

  public dismiss(): void {
    this.onDismiss.emit(null);
  }
}

import { Component, OnInit, forwardRef, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Currency } from '../currency.model';
import { CurrencyService } from '../currency.service';
import { AutoComplete } from 'primeng/primeng';

export const CURRENCY_SELECTOR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CurrencySelectorComponent),
  multi: true
};

@Component({
  selector: 'currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
  providers: [CURRENCY_SELECTOR_VALUE_ACCESSOR]
})
export class CurrencySelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public selectedOption = null;
  public options: Currency[] = [];
  public disabled: boolean = false;

  private modelTouched: () => void = () => { };
  private modelChanged: (value: string) => void = () => { };
  private listSubscription = new Subscription();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private currencyService: CurrencyService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.listSubscription.unsubscribe();
  }

  writeValue(value: any): void {
    if (
      'string' !== typeof value ||
      '' === value
    ) {
      this.selectedOption = null;
      this.changeDetector.markForCheck();

      return;
    }

    this.currencyService.getById(value)
    .subscribe(
      matchingCurrency => {
        this.selectedOption = matchingCurrency;
        this.changeDetector.markForCheck();
      }
    );
  }

  registerOnChange(fn): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn): void {
    this.modelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public onBlur(): void {
    this.modelTouched();
  }

  public onSelect(currency: Currency): void {
    this.modelChanged(currency.getId());
  }

  public onClear(): void {
    this.modelChanged(null);
  }

  public search(query: string): void {
    const limit = 20;

    this.listSubscription.add(
      this.currencyService
      .query(query, limit)
      .subscribe(
        (currenciesOptions) => {
          this.options = currenciesOptions;
        }
      )
    );
  }

}

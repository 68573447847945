import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotifyService, ToastType } from '../notify';

@Injectable()
export class HttpError implements HttpInterceptor {

  private ignoredErrors = [400, 401];

  constructor(
    private router: Router,
    private notify: NotifyService,
    private translate: TranslateService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse, caught: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> => {

          if (this.ignoredErrors.indexOf(error.status) !== -1) {
            return throwError(error);
          }

          if (404 === error.status) {
            this.router.navigate(['404']);

            return of(null);
          }

          if (
            !req.headers.has('Ignore-Errors') ||
            error.status >= 500
          ) {
            this.notify.toast(
              ToastType.ERROR,
              this.translate.instant('message.server_error', { code: error.status })
            );
          }

          return throwError(error);
        })
      );
  }
}

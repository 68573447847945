import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export class AppValidators {

  public static email(abstractControl: AbstractControl): ValidationErrors {
    const emailRegExp: RegExp = /^[^@]+@[^@]*[^\.@]$/i; // one @ symbol, not ending with .

    if (
      !abstractControl.value ||
      emailRegExp.test(abstractControl.value)
    ) {
      return null;
    }

    return {
        email: true
      };
  }

  public static number(abstractControl: AbstractControl): ValidationErrors {
    const numberRegExp: RegExp = /^\d+((\.|,)\d+)?$/;

    if (
      '' === abstractControl.value ||
      numberRegExp.test(abstractControl.value)
    ) {
      return null;
    }

    return {
        number: true
      };
  }

  public static moreThan(min: number): ValidatorFn {

    return (abstractControl: AbstractControl) => {
      if (
        isNaN(abstractControl.value) ||
        abstractControl.value > min
      ) {
        return null;
      }

      return {
          moreThan: { min }
        };
    }
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map, shareReplay } from 'rxjs/operators';

import { Language } from './language.model';
import { LanguageRepository } from './language.repository';

@Injectable()
export class LanguageService {

  private languages: Observable<Language[]>;

  constructor(
    languageRepository: LanguageRepository
  ) {
    this.languages = languageRepository.getLanguages()
      .pipe(
        shareReplay(1)
      );

      this.loadLanguages();
  }

  public getById(id: string): Observable<Language> {
    const serializedId = id.toLocaleLowerCase();

    return this.languages
        .pipe(
          take(1),
          map(
            languages => languages
              .find(language => language.matchId(serializedId))
          )
        );
  }

  public getLanguages(): Observable<Language[]> {
    return this.languages;
  }

  private loadLanguages() {
    this.languages
    .pipe(
      take(1)
    )
    .subscribe(
      () => { },
      () => { }
    );
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthHttpClientModule, REFRESH_TOKEN_API_URL, REFRESH_TOKEN_STORAGE_NAME } from '@dvs-angular/auth-http-client';
import { StorageModule, Storage, CookiesStorage } from '@dvs-angular/storage';
import { PageLoaderModule } from '@dvs-angular/page-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { map } from 'rxjs/operators';
import { ButtonModule } from 'primeng/button';
import { DomHandler } from 'primeng/api';
import localeEn from '@angular/common/locales/en-US-POSIX';

import { environment } from '../environments/environment';
import { NotifyModule } from './notify';
import { UiModule } from './ui';
import { PermissionModule, PermissionService } from './permission';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserModule, UserService } from './user';
import { ROLE } from './user/role.enum';
import { PrimengHelpersModule, CurrencyModule } from './shared';
import { UploaderModule } from './uploader';
import { LanguageModule } from './shared/language/language.module';

export function TranslateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.selfUrl + '/assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NotifyModule.forRoot(),
    PermissionModule.forRoot(),
    PageLoaderModule,
    UiModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthHttpClientModule,
    ButtonModule,
    StorageModule,
    UserModule,
    AppRoutingModule,
    PrimengHelpersModule,
    UploaderModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CurrencyModule.forRoot(),
    LanguageModule.forRoot()
  ],
  providers: [
    {
      provide: Storage,
      useExisting: CookiesStorage
    },
    {
      provide: REFRESH_TOKEN_API_URL,
      useValue: environment.api.shoppingpanel + '/token/refresh'
    },
    {
      provide: REFRESH_TOKEN_STORAGE_NAME,
      useValue: 'refresh_token'
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(
    permissionService: PermissionService,
    userService: UserService,
    translate: TranslateService
  ) {

    permissionService.defineRole(
      ROLE.GUEST,
      () => userService.loadUser()
        .pipe(
          map(user => user === null)
        )
    );

    permissionService.defineRole(
      ROLE.USER,
      () => userService.loadUser()
        .pipe(
          map(user => user !== null)
        )
    );

    permissionService.defineRole(
      ROLE.SHOPPING_PANEL_SUPPLIER,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.SHOPPING_PANEL_SUPPLIER)))
        ),
      [ROLE.USER]
    );

    permissionService.defineRole(
      ROLE.SHOPPING_PANEL_APPROVER,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.SHOPPING_PANEL_APPROVER)))
        ),
      [ROLE.USER]
    );

    permissionService.defineRole(
      ROLE.SHOPPING_PANEL_BUYER,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.SHOPPING_PANEL_BUYER)))
        ),
      [ROLE.USER]
    );

    permissionService.defineRole(
      ROLE.SHOPPING_PANEL_ADMIN,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.SHOPPING_PANEL_ADMIN)))
        ),
      [ROLE.USER]
    );

    permissionService.defineRole(
      ROLE.AUCTION_PANEL_AUCTION_MANAGEMENT,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.AUCTION_PANEL_AUCTION_MANAGEMENT)))
        ),
      [ROLE.USER]
    );

    permissionService.defineRole(
      ROLE.SUPER_ADMIN,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.SUPER_ADMIN)))
        ),
      [ROLE.SHOPPING_PANEL_BUYER, ROLE.SHOPPING_PANEL_SUPPLIER, ROLE.SHOPPING_PANEL_APPROVER]
    );

    permissionService.defineRole(
      ROLE.SHOPPING_PANEL_OBSERVER,
      () => userService.loadUser()
        .pipe(
          map(user => (user !== null && user.hasRole(ROLE.SHOPPING_PANEL_OBSERVER)))
        ),
      [ROLE.USER]
    );

    registerLocaleData(localeEn);
    translate.setDefaultLang('en_US');
    translate.use('en_US');

    DomHandler.zindex = 5000;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/primeng';

import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';
import { CurrencyRepository } from './currency.repository';
import { CurrencyService } from './currency.service';
import { CurrencyPipe } from './currency.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    TranslateModule.forChild()
  ],
  declarations: [
    CurrencySelectorComponent,
    CurrencyPipe
  ],
  exports: [
    CurrencySelectorComponent,
    CurrencyPipe
  ]
})
export class CurrencyModule {

  static forRoot() {
    return {
      ngModule: CurrencyModule,
      providers: [
        CurrencyRepository,
        CurrencyService
      ]
    };
  }

  static forChild() {
    return {
        ngModule: CurrencyModule
    };
  }

}

import { Approval } from './approval.model';
import { Buyer } from './buyer.model';
import { DATABASE_TYPE } from './database-type.enum';
import { Attachment } from './shared/attachment/attachment.model';
import { Category } from './shared/category/category.model';
import { Company } from './shared/company/company.model';
import { PurchaseProjectStatus } from './purchase-project-status.enum';
import { ReferenceValue } from './shared/reference/reference-value.model';
import { SupplierListItem } from './shared/supplier/supplier-list-item.model';
import { Tco } from './shared/tco/tco.model';
import { Template } from './shared/template/template.model';
import { Recommendation } from './recommendation.model';

export class PurchaseProject {

  constructor(
    private id: number,
    public createdAt: Date,
    public name: string,
    public description: string,
    public sop: Date,
    public category: Category,
    public buyer: Buyer,
    public company: Company,
    public type: string,
    public template: Template,
    public suppliers: SupplierListItem[],
    public tco: Tco[],
    public references: ReferenceValue[],
    public attachments: Attachment[],
    public specificationDescription: string,
    public status: PurchaseProjectStatus,
    public offerSubmissionDate: Date,
    public recommendation: Recommendation,
    public currency: string,
    public database: DATABASE_TYPE,
    public approvals: Approval[] = [],
    public archived: boolean = false
  ) { }

  public getId(): number {
    return this.id;
  }

  public getExpectedTPV(): number {
    if (!(this.references instanceof Array)) {
      return 0;
    }

    return this.references
      .reduce((sum, item) => sum + item.quantity * item.expectedPrice, 0);
  }

  public getRequiredTPV(): number {
    if (!(this.references instanceof Array)) {
      return 0;
    }

    return this.references
      .reduce((sum, item) => sum + item.quantity * item.requiredPrice, 0);
  }

  public getPublicTco() {
    return this.tco.filter(tcoItem => tcoItem.isPublic);
  }

  public getPrivateTco() {
    return this.tco.filter(tcoItem => !tcoItem.isPublic);
  }

}

import { Component, ElementRef, ViewChild } from '@angular/core';

import { ModalComponent } from '../modal-component';
import { ToastType } from './toast-type.enum';

@Component({
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent extends ModalComponent<void> {

  public toastClass: string = '';
  public iconClass: string = '';
  public isActive: boolean = true;
  public height: number = 0;
  public title: string = '';
  public details: string = '';
  @ViewChild('toast')
  public toastRef: ElementRef;

  private lifetime: number = 3000;
  private destroyTimeout;

  constructor() {
    super();
  }

  public show(type: ToastType, title: string, details: string) {
    this.title = title;
    this.details = details;

    this.setClasses(type);

    this.destroyTimeout = setTimeout(
        () => { this.destroy(); },
        this.lifetime + 500
      );
  }

  public destroy() {
    if (!this.isActive) {
      return;
    }

    this.height = this.toastRef.nativeElement.offsetHeight;
    this.isActive = false;

    clearTimeout(this.destroyTimeout);
    setTimeout(() => { this.dismiss(); }, 700);
  }

  private setClasses(toastType: ToastType) {
    switch (toastType) {

      case ToastType.WARN:
        this.toastClass = 'warn';
        this.iconClass = 'pi pi-exclamation-triangle';
        break;

      case ToastType.ERROR:
        this.toastClass = 'error';
        this.iconClass = 'fa fa-exclamation';
        break;

      case ToastType.SUCCESS:
        this.toastClass = 'success';
        this.iconClass = 'pi pi-check';
        break;

      default: // ToastType.INFO
        this.toastClass = 'info';
        this.iconClass = 'pi pi-info';
        break;

    }
  }

}

export enum ROLE {
  GUEST = 'ROLE_GUEST',
  USER = 'ROLE_USER',
  SHOPPING_PANEL_BUYER = 'ROLE_SHOPPING_PANEL_BUYER',
  SHOPPING_PANEL_SUPPLIER = 'ROLE_SHOPPING_PANEL_SUPPLIER',
  SHOPPING_PANEL_APPROVER = 'ROLE_SHOPPING_PANEL_APPROVER',
  SHOPPING_PANEL_ADMIN = 'ROLE_SHOPPING_PANEL_ADMIN',
  SHOPPING_PANEL_OBSERVER = 'ROLE_SHOPPING_PANEL_OBSERVER',
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  SHOPPING_PANEL_DIRECT = 'ROLE_SHOPPING_PANEL_DIRECT',
  SHOPPING_PANEL_INDIRECT = 'ROLE_SHOPPING_PANEL_INDIRECT',
  AUCTION_PANEL_AUCTION_MANAGEMENT = 'ROLE_AUCTION_PANEL_AUCTION_MANAGEMENT'
}

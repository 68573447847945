import { ViewChild, Component, ElementRef } from '@angular/core';
import { FileUpload } from 'primeng/primeng';
import { TokenService } from '@dvs-angular/auth-http-client';

import { ModalComponent, NotifyService, ToastType } from '../../notify';
import { UploaderOptions } from '../uploader-options.model';
import { UploadedFile } from '../uploaded-file.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './modal-uploader.component.html',
  styleUrls: ['./modal-uploader.component.scss']
})
export class ModalUploaderComponent extends ModalComponent<UploadedFile[]> {

  @ViewChild('closeButton')
  public closeButton: ElementRef;
  @ViewChild('fileUpload')
  public fileUpload: FileUpload;
  public options: UploaderOptions = null;
  public toasts: any[] = [];

  constructor(
    private tokenService: TokenService,
    private notify: NotifyService,
    private translate: TranslateService
  ) {
    super();
  }

  ngAfterViewInit() {
    this.closeButton.nativeElement.focus();
  }

  public setOptions(options: UploaderOptions): void {
    this.options = options;
  }

  public upload(): void {
    this.fileUpload.upload();
  }

  public canUpload(): boolean {
    return this.fileUpload.hasFiles();
  }

  public uploadSuccess(files: File[], xhr: XMLHttpRequest): void {
    this.notify.toast(ToastType.SUCCESS, this.translate.instant('uploader_modal.message.success'));
    const response = (xhr.response instanceof Array) ? xhr.response : [xhr.response];

    super.close(
      files.map(
        (file: File, index: number) => new UploadedFile(file, response[index] || {})
      )
    );
  }

  public uploadError(request: XMLHttpRequest): void {
    this.notify.alert(
      this.translate.instant('uploader_modal.message.format_error'),
      this.translate.instant('uploader_modal.message.error'));
  }

  public attachAuthHeaders(request: XMLHttpRequest): void {
    request.responseType = 'json';

    if (!this.options.auth) {
      return;
    }

    const token = this.tokenService.getPrefixedToken();
    const tokenHeaderName = this.tokenService.getTokenHeaderName();

    request.setRequestHeader(tokenHeaderName, token);
  }

}

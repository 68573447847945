import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { NotifyService } from './notify.service';
import { ContainerService } from './container.service';
import { NotifyContainerComponent } from './notify-container/notify-container.component';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    TranslateModule.forChild()
  ],
  declarations: [
    AlertComponent,
    NotifyContainerComponent,
    ConfirmComponent,
    ToastComponent
  ],
  entryComponents: [
    AlertComponent,
    ConfirmComponent,
    ToastComponent
  ],
  exports: [
    NotifyContainerComponent
  ]
})
export class NotifyModule {

  static forRoot() {
    return {
      ngModule: NotifyModule,
      providers: [
        NotifyService,
        ContainerService
      ]
    };
  }

  static forChild() {
    return {
        ngModule: NotifyModule,
        providers: [
          NotifyService
        ]
    };
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OptionValueDirective } from './options-value.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    OptionValueDirective
  ],
  exports: [
    OptionValueDirective
  ]
})
export class PrimengHelpersModule { }

import { Injectable, ViewContainerRef } from '@angular/core';

import { NotifyContainerComponent } from './notify-container/notify-container.component';

@Injectable()
export class ContainerService {

  private container: NotifyContainerComponent = null;

  constructor() { }

  public setContainer(container: NotifyContainerComponent) {
    this.container = container;
  }

  public getModalContainer(): ViewContainerRef {
    if (null === this.container) {
      return undefined;
    }

    return this.container.getModalContainer();
  }

  public getToastContainer(): ViewContainerRef {
    if (null === this.container) {
      return undefined;
    }

    return this.container.getToastContainer()
  }

}

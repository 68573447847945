import { Component, Input, Output, EventEmitter, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const BATCH_CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BatchCheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-batch-checkbox',
  templateUrl: './batch-checkbox.component.html',
  styleUrls: ['./batch-checkbox.component.scss'],
  providers: [BATCH_CHECKBOX_VALUE_ACCESSOR]
})
export class BatchCheckboxComponent implements ControlValueAccessor {

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();
  @Input()
  public checkedField: string = 'checked';
  @Input()
  public disabled: boolean = false;
  private items: any[] = [];
  private modelTouched: () => void = () => { };
  private modelChanged: (value: any[]) => void = () => { };

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  writeValue(value: any): void {
    this.items = value;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn): void {
    this.modelTouched = fn;
  }

  public set isChecked(value: boolean) { }

  public get isChecked(): boolean {
    if (null === this.items) {
      return false;
    }

    for (let item of this.items) {
      if (item[this.checkedField] === true) {
        return true;
      }
    }

    return false;
  }

  public get isHalfChecked(): boolean {
    if (null === this.items) {
      return false;
    }

    for (let item of this.items) {
      if (item[this.checkedField] === false) {
        return true;
      }
    }

    return false;
  }

  public checkboxClicked(event):void {
    event.stopPropagation();
  }

  public checkboxOnChange(checked: boolean): void {
    if (checked) {
      this.updateItems(true);

      return;
    }

    let checkedItemsCount: number = this.items
      .filter((item) => item[this.checkedField] === true)
      .length;

    if (checkedItemsCount < this.items.length / 2) {
      this.updateItems(true);

      return;
    }

    this.updateItems(false);
  }

  public checkboxOnBlur() {
    this.modelTouched();
  }

  private updateItems(checkedValue: boolean): void {
    this.items = this.items
      .map((item) => {
        item[this.checkedField] = checkedValue;

        return item;
      });

    this.modelChanged(this.items);
    this.onChange.emit(this.items);
  }

}

import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';

export class ReactiveFormsUtils {

  public static markAsTouched(control: FormGroup | FormArray | FormControl | AbstractControl): void {
    if (control instanceof FormGroup) {
      const childControls = Object
        .keys(control.controls)
        .map(controlName => control.get(controlName));

      for (const childControl of childControls) {
        this.markAsTouched(childControl);
      }

      return;
    }

    if (control instanceof FormArray) {
      for (const childControl of control.controls) {
        this.markAsTouched(childControl);
      }

      return;
    }

    if (control instanceof FormControl) {
      control.markAsTouched();
    }
  }

}

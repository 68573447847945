import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, FileUploadModule, DialogModule, DragDropModule } from 'primeng/primeng';

import { NotifyModule } from '../notify';
import { ModalUploaderComponent } from './modal-uploader/modal-uploader.component';
import { UploaderService } from './uploader.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FileUploadModule,
    NotifyModule.forChild(),
    DialogModule,
    DragDropModule,
    TranslateModule
  ],
  declarations: [
    ModalUploaderComponent
  ],
  entryComponents: [
    ModalUploaderComponent
  ]
})
export class UploaderModule {

  static forRoot() {
    return {
      ngModule: UploaderModule,
      providers: [UploaderService]
    };
  }

  static forChild() {
    return {
        ngModule: UploaderModule
    };
  }

}

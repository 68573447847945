export class User {

  constructor(
    private id: number,
    private roles: string[],
    public username: string,
    public firstName: string,
    public lastName: string
  ) { }

  public getId(): number {
    return this.id;
  }

  public hasRole(role: string): boolean {
    return -1 !== this.roles.indexOf(role);
  }

  public getFullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ValidationError } from './validation-error.model';

@Component({
  selector: 'validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss']
})
export class ValidationErrorComponent implements OnInit, OnDestroy {

  public errorsVisibility = false;
  public errors: ValidationError[] = [];

  @Input('targetFormControl')
  private targetFormControl: AbstractControl;
  private formControlSubscription = new Subscription();

  constructor() { }

  ngOnInit() {
    if (null !== this.targetFormControl) {
      this.formControlSubscription.add(
        this.targetFormControl.statusChanges
        .pipe(
          distinctUntilChanged(
            (prev, current) => (
              prev === current &&
              'VALID' === current
            )
          )
        )
        .subscribe(() => {
          this.updateErrors();
        })
      );
    }

    const originalMarkAsTouched = this.targetFormControl.markAsTouched;
    this.targetFormControl.markAsTouched = (options) => {
        originalMarkAsTouched.call(this.targetFormControl, options);
        this.updateErrors();
      };

    this.updateErrors();
  }

  ngOnDestroy() {
    this.formControlSubscription.unsubscribe();
  }

  public updateErrors(): boolean {
    this.errors = [];

    if (
      null === this.targetFormControl ||
      null === this.targetFormControl.errors
    ) {
      this.errorsVisibility = false;

      return;
    }

    for (const errorKey in this.targetFormControl.errors) {
      if (this.targetFormControl.errors.hasOwnProperty(errorKey)) {
        this.errors.push(
          new ValidationError(
            errorKey,
            this.targetFormControl.errors[errorKey]
          )
        )
      }
    }

    this.errorsVisibility = (!this.targetFormControl.valid) && this.targetFormControl.touched;
  }

}

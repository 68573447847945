import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { UserRepository } from './user.repository';
import { UserService } from './user.service';
import { UserRoutingModule } from './user-routing.module';
import { UserResolve } from './user.resolve';
import { NotifyModule } from '../notify';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    TooltipModule,
    NotifyModule.forChild(),
    UserRoutingModule,
    TranslateModule
  ],
  exports: [LoginComponent],
  declarations: [
    LoginComponent,
    ProfileComponent
  ],
  providers: [
    UserRepository,
    UserService,
    UserResolve
  ]
})
export class UserModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DecimalPipe } from './decimal.pipe';
import { HttpError } from './http-error.interceptor';
import { SonataLinkDirective } from './sonata-link.directive';
import { UiLockComponent } from './ui-lock/ui-lock.component';
import { NumberFormatPipe } from './number-format.pipe';
import { TrustHtmlPipe } from './trustHtml.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PageNotFoundComponent,
    SonataLinkDirective,
    UiLockComponent,
    DecimalPipe,
    NumberFormatPipe,
    TrustHtmlPipe
  ],
  providers: [
    SonataLinkDirective
  ],
  exports: [
    SonataLinkDirective,
    UiLockComponent,
    DecimalPipe,
    NumberFormatPipe,
    TrustHtmlPipe
  ]
})
export class UiModule {

  static forRoot() {
    return {
        ngModule: UiModule,
        providers: [
          DecimalPipe,
          NumberFormatPipe,
          HttpError,
          {
            provide: HTTP_INTERCEPTORS,
            useExisting: HttpError,
            multi: true
          }
        ]
      };
  }

  static forChild() {
    return {
        ngModule: UiModule,
        providers: [
          HttpError,
          {
            provide: HTTP_INTERCEPTORS,
            useExisting: HttpError,
            multi: true
          }
        ]
      };
  }

}

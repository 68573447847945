import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './ui';

const AUCTION_MODULE = './auction/auction.module#AuctionModule';
const PURCHASE_PROJECT_MODULE = './purchase-project/purchase-project.module#PurchaseProjectModule';


const routes: Routes = [
  {
    path: 'auction',
    loadChildren: AUCTION_MODULE,
    data: { base: true }
  },
  {
    path: 'buyer/auction',
    loadChildren: AUCTION_MODULE,
    data: { base: true }
  },
  {
    path: 'supplier/auction',
    loadChildren: AUCTION_MODULE,
    data: { base: true }
  },
  {
    path: 'purchase-project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: 'supplier/project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: 'buyer/rfi/project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: 'buyer/rfq/project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: 'archive/project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: 'approver/project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: 'observer/project',
    loadChildren: PURCHASE_PROJECT_MODULE,
    data: { base: true }
  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*
--- AVAILABLE URLS:

/admin/purchase-project/{id}/edit/*
/admin/purchase-project/{id}/fill
/admin/purchase-project/{id}/show

/admin/supplier/project/{id}/fill/*
/admin/supplier/project/{id}/show

/admin/buyer/rfi/project/{id}/edit/*
/admin/buyer/rfi/project/{id}/fill
/admin/buyer/rfi/project/{id}/show

/admin/buyer/rfq/project/{id}/edit/*
/admin/buyer/rfq/project/{id}/fill
/admin/buyer/rfq/project/{id}/show

/admin/archive/project/{id}/show

/admin/approver/project/{id}/show

/admin/observer/project/{id}/show
*/

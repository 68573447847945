export class Currency {

  private serializedName = '';
  private serializedId = '';

  constructor(
    private id: string,
    public name: string
  ) {
    this.serializedId = id.toLocaleLowerCase();
    this.serializedName = name.toLocaleLowerCase();
  }

  public getId(): string {
    return this.id;
  }

  public match(query: string): boolean {
    return (
        -1 < this.serializedId.indexOf(query) ||
        -1 < this.serializedName.indexOf(query)
      );
  }

  public matchId(id: string): boolean {
    return -1 < this.serializedId.indexOf(id);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { mergeMap, tap, finalize } from 'rxjs/operators';
import { TokenService, Token } from '@dvs-angular/auth-http-client';
import { TranslateService } from '@ngx-translate/core';

import { NotifyService, ToastType } from '../../notify';
import { UserRepository } from '../user.repository';
import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  private loginSubscription: Subscription = null;

  constructor(
    private formBuilder: FormBuilder,
    private userRepository: UserRepository,
    private tokenService: TokenService,
    private userService: UserService,
    private notify: NotifyService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loginForm = this.buildLoginForm();
  }

  ngOnDestroy() {
    if (null !== this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  buildLoginForm() {
    return this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
  }

  public login(event: Event): void {
    event.preventDefault();

    if (null !== this.loginSubscription) {
      return;
    }

    this.loginSubscription = this.userRepository.login(
        this.loginForm.value.username,
        this.loginForm.value.password
      )
      .pipe(
        tap(token => { this.tokenService.setToken(token); }),
        mergeMap(token => this.userService.loadUser(token.value)),
        finalize(() => { this.loginSubscription = null; })
      )
      .subscribe(
        (user: User) => {
          this.router.navigate(['profile']);
        },
        (error: HttpErrorResponse) => {
          this.notify.toast(
            ToastType.ERROR,
            this.translate.instant('login.message.bad_credentials')
          );
          this.loginForm.get('password').reset();
        }
      );
  }

}

export class Language {

  private serializedId = '';

  constructor(
    private id: string,
    public name: string
  ) {
    this.serializedId = id.toLocaleLowerCase();
  }

  public getId(): string {
    return this.id;
  }

  public matchId(id: string): boolean {
    return -1 < this.serializedId.indexOf(id);
  }

}

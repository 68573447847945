import { Component, OnInit, forwardRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Language } from '../language.model';
import { LanguageService } from '../language.service';

export const LANGUAGE_SELECTOR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LanguageSelectorComponent),
  multi: true
};

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  providers: [LANGUAGE_SELECTOR_VALUE_ACCESSOR]
})
export class LanguageSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public selectedOption = null;
  public languages: Language[] = [];

  public modelTouched: () => void = () => { };
  public modelChanged: (value: string) => void = () => { };
  private listSubscription = new Subscription();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.languageService.getLanguages()
    .subscribe(
      (options) => {
        this.languages = options;
    });
  }

  ngOnDestroy() {
    this.listSubscription.unsubscribe();
  }

  writeValue(value: any) {
    if (
      'string' !== typeof value ||
      '' === value
    ) {
      this.selectedOption = null;
      this.changeDetector.markForCheck();

      return;
    }

    this.languageService.getById(value)
    .subscribe(
      matchingLanguage => {
        this.selectedOption = matchingLanguage;
        this.changeDetector.markForCheck();
      }
    );
  }

  public onChange(language: Language): void {
    this.modelChanged(language.getId());
  }

  registerOnChange(fn): void {
    this.modelChanged = fn;
  }

  registerOnTouched(fn): void {
    this.modelTouched = fn;
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@dvs-angular/auth-http-client';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, take, distinctUntilChanged, mergeMap, shareReplay, filter } from 'rxjs/operators';

import { User } from './user.model';
import { UserRepository } from './user.repository';

@Injectable()
export class UserService {

  private user$: Observable<User> = null;
  private tokenHelper: JwtHelperService = new JwtHelperService();
  private selfInfoSubject = new ReplaySubject<boolean>(1);
  private selfInfoRequest: Observable<any> = null;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private userRepository: UserRepository
  ) {
    this.selfInfoRequest = this.getSelfInfoStream();
  }

  public loadUser(token = this.loadToken()): Observable<User> {
    if (null !== this.user$) {
      return this.user$;
    }

    if (
      'string' !== typeof token ||
      '' === token
    ) {
      return of(null);
    }

    const decodedToken: any = this.tokenHelper.decodeToken(token);

    this.user$ = of(new User(
        decodedToken.id || null,
        decodedToken.roles,
        decodedToken.username,
        decodedToken.firstName || '',
        decodedToken.lastName || ''
      ));

    return this.user$;
  }

  public selfInfo(): Observable<any> {
    this.selfInfoSubject.next(true);

    return this.selfInfoRequest.pipe(take(1));
  }

  public logout(): void {
    this.tokenService.clearToken();
    this.user$ = null;

    this.router.navigate(['login']);
  }

  public clearSelfInfoCache(): void {
    this.selfInfoSubject.next(false);
  }

  private loadToken(): string {
    return this.tokenService.getTokenString();
  }

  private getSelfInfoStream(): Observable<any> {
    return this.selfInfoSubject
      .pipe(
        distinctUntilChanged(),
        filter(loadRequired => loadRequired),
        mergeMap(() => this.fetchSelfInfo()),
        shareReplay(1)
      );
  }

  private fetchSelfInfo(): Observable<any> {

    return this.userRepository.selfInfo()
      .pipe(
        map(selfInfoData => {
          const clearedData = {};

          for (const key in selfInfoData) {
            if (null !== selfInfoData[key]) {
              clearedData[key] = selfInfoData[key];
            }
          }

          return clearedData;
        })
      );
  }

}

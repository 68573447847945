import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: any, digits: number): string {
    if (!this.isNumber(value)) {
      return value;
    }

    const digitsNumber = this.isNumber(digits) ? digits : 2;

    return Number(value).toFixed(digitsNumber);
  }

  private isNumber(value: any): boolean {
    return (
        null !== value &&
        !isNaN(value)
      );
  }

}

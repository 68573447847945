import { Directive, Input, OnChanges, SimpleChanges, Optional } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';

@Directive({
  selector: '[optionValue]'
})
export class OptionValueDirective implements OnChanges {

  @Input()
  public options: any[];
  @Input()
  public optionValue: string;
  @Input()
  public optionValueLabel: string;

  constructor(
    @Optional()
    private dropdown: Dropdown,
    @Optional()
    private multiSelect: MultiSelect
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('options') ||
      changes.hasOwnProperty('optionValue')
    ) {
      this.updateOptions();
    }
  }

  private updateOptions(): void {
    const target = this.getTarget();

    if (null === target) {
      return;
    }

    target.options = (this.options || []).map(option => {
        const optionWithValue = { value: option[this.getOptionValue()] };
        optionWithValue['label'] = option[this.getOptionLabel()];

        return optionWithValue;
      });
  }

  private getOptionValue(): string {
    return this.optionValue || 'value';
  }

  private getOptionLabel(): string {
    return this.optionValueLabel || 'label';
  }

  private getTarget(): Dropdown | MultiSelect {
    return this.dropdown || this.multiSelect || null;
  }

}

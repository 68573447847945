import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any): string {
    if (!this.isNumber(value)) {
      return value;
    }

    const stringValue = value.toString();
    const dotLastIndex = stringValue.lastIndexOf('.');
    const comaLastIndex = stringValue.lastIndexOf(',');

    const separator = (dotLastIndex > comaLastIndex) ? '.' : ',';
    const blocks = value.toString().split(separator);

    if (2 < blocks.length || 0 === blocks.length) {
      return value;
    }

    blocks[0] = blocks[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return blocks.join(separator);
  }

  private isNumber(value: any): boolean {
    return (
        null !== value &&
        !isNaN(value)
      );
  }

}

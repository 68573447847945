import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotifyService } from '../notify';
import { UploaderOptions } from './uploader-options.model';
import { ModalUploaderComponent } from './modal-uploader/modal-uploader.component';
import { UploadedFile } from './uploaded-file.model';

@Injectable()
export class UploaderService {

  constructor(
    private notify: NotifyService
  ) { }

  public openModal(options: UploaderOptions): Observable<UploadedFile[]> {
    return this.notify.modal<ModalUploaderComponent, UploadedFile[]>(ModalUploaderComponent, options);
  }

}

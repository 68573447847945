import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'max-length-counter',
  templateUrl: './max-length-counter.component.html',
  styleUrls: ['./max-length-counter.component.scss']
})
export class MaxLengthCounterComponent implements OnInit, OnDestroy {

  @Input('maxLength')
  public limit: number;
  public count = 0;

  @Input('targetFormControl')
  private targetFormControl: AbstractControl;
  private valueSubscription = new Subscription();

  constructor() { }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }

  ngOnInit() {
    if (
      null !== this.targetFormControl &&
      null !== this.limit &&
      !isNaN(this.limit)
    ) {
      this.valueSubscription.add(
        this.targetFormControl
        .valueChanges
        .subscribe(
          value => { this.updateValueCount(value); }
        )
      );

      this.updateValueCount(this.targetFormControl.value);
    }
  }

  private updateValueCount(value: any): void {
    this.count = this.hasLength(value) ? value.length : 0;
  }

  private hasLength(value: any): boolean {
    return (
        'string' === typeof value ||
        value instanceof Array
      );
  }

}

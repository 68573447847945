import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PermissionService } from '../permission';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { ROLE } from './role.enum';
import { UserResolve } from './user.resolve';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PermissionService],
    data: {
      only: [ROLE.GUEST],
      redirectTo: 'profile'
    }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [PermissionService],
    resolve: {
      user: UserResolve
    },
    data: {
      only: [ROLE.USER],
      redirectTo: 'login'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }

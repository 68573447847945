import { Injectable } from '@angular/core';
import { AuthHttpClient } from '@dvs-angular/auth-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Currency } from './currency.model';

@Injectable()
export class CurrencyRepository {

  constructor(
    private http: AuthHttpClient
  ) { }

  public list(): Observable<Currency[]> {
    return this.http.get(environment.api.shoppingpanel + '/currency')
      .pipe(
        map(result => result instanceof Array ? result : []),
        map(result => result.map(
          currenctData => new Currency(currenctData.id, currenctData.name)
        ))
      );
  }

}

import { Injectable, Component, ViewContainerRef, ComponentRef, ComponentFactoryResolver, ComponentFactory, Type } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ModalComponent } from './modal-component';
import { ToastComponent } from './toast/toast.component';
import { ToastType } from './toast/toast-type.enum';
import { ContainerService } from './container.service';

@Injectable()
export class NotifyService {

  private registeredModals: ComponentRef<any>[] = [];

  constructor(
    private cfr: ComponentFactoryResolver,
    private container: ContainerService,
    private translate: TranslateService
  ) { }

  public alert(message: string, title: string = this.translate.instant('notify.alert')) {
    if (undefined === this.container.getModalContainer()) {
      return;
    }

    const componentRef: ComponentRef<AlertComponent> = this.createComponent<AlertComponent>(
        AlertComponent,
        this.container.getModalContainer()
      );

    componentRef.instance.setMessage(message);
    componentRef.instance.setTitle(title);

    return this.watchModal<AlertComponent, void>(componentRef);
  }

  public confirm(message: string, title: string = this.translate.instant('notify.confirm')) {
    if (undefined === this.container.getModalContainer()) {
      return;
    }

    const componentRef: ComponentRef<ConfirmComponent> = this.createComponent<ConfirmComponent>(
        ConfirmComponent,
        this.container.getModalContainer()
      );

    componentRef.instance.setMessage(message);
    componentRef.instance.setTitle(title);

    return this.watchModal<ConfirmComponent, void>(componentRef);
  }

  public modal<T extends ModalComponent<U>, U>(component: Type<T>, options: any = {}): Observable<U> {
    if (undefined === this.container.getModalContainer()) {
      return;
    }

    const componentRef: ComponentRef<T> = this.createComponent<T>(
        component,
        this.container.getModalContainer()
      );
    componentRef.instance.setOptions(options);

    return this.watchModal<T, U>(componentRef);
  }

  public killAllModals() {
    while (this.registeredModals.length > 0) {
      this.registeredModals.shift().destroy();
    }
  }

  public toast(type: ToastType, title: string, details?: string): void {
    if (undefined === this.container.getToastContainer()) {
      return;
    }

    const componentRef: ComponentRef<ToastComponent> = this.createComponent<ToastComponent>(
        ToastComponent,
        this.container.getToastContainer()
      );

    componentRef.instance.show(type, title, details);
    this.watchModal<ToastComponent, void>(componentRef);
  }

  private watchModal<T extends ModalComponent<U>, U>(componentRef: ComponentRef<T>): Observable<U> {
    componentRef.instance.onClose.subscribe((result: U) => {
      componentRef.destroy();
    });

    componentRef.instance.onDismiss.subscribe(() => {
      componentRef.destroy();
    });

    this.registeredModals.push(componentRef);

    componentRef.onDestroy(() => {
      this.registeredModals = this.registeredModals
        .filter((registeredModal) => registeredModal !== componentRef);
    });

    return new Observable<U>((observer: Subscriber<U>): void => {

        componentRef.instance.onClose.subscribe((result: U) => {
          observer.next(result);
          observer.complete();
        });

        componentRef.instance.onDismiss.subscribe(() => {
          observer.error();
        });

      });
  }

  private createComponent<T>(component: Type<T>, target: ViewContainerRef): ComponentRef<T> {
    const componentFactory: ComponentFactory<T> = this.cfr.resolveComponentFactory<T>(component);

    return target.createComponent<T>(componentFactory);
  }

}

import { Component, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';

import { ContainerService } from '../container.service';

@Component({
  selector: 'notify-container',
  templateUrl: './notify-container.component.html',
  styleUrls: ['./notify-container.component.scss']
})
export class NotifyContainerComponent implements AfterViewInit {

  @ViewChild('modalContainer', { read: ViewContainerRef })
  public modalContainerRef: ViewContainerRef;

  @ViewChild('toastContainer', { read: ViewContainerRef })
  public toastContainerRef: ViewContainerRef;

  constructor(
    private containerService: ContainerService
  ) { }

  ngAfterViewInit() {
    this.containerService.setContainer(this);
  }

  public getModalContainer(): ViewContainerRef {
    return this.modalContainerRef;
  }

  public getToastContainer(): ViewContainerRef {
    return this.toastContainerRef;
  }

}

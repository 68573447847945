import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  public user: User;

  constructor(
    private userService: UserService,
    route: ActivatedRoute
  ) {
    this.user = route.snapshot.data.user;
  }

  public logout(): void {
    this.userService.logout();
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Injectable()
export class ListNavigatorService {

  constructor(
    private activatedRoute: ActivatedRoute,
    private platformLocation: PlatformLocation
  ) { }

  public goToList() {
    const baseRoute = this.findBaseRoute(this.activatedRoute);

    if (null === baseRoute) {
      return;
    }

    const listUrl = [
        ...this.platformLocation.getBaseHrefFromDOM()
          .split('/'),
        ...baseRoute.pathFromRoot
          .reduce((parts, route) => parts.concat(route.snapshot.url), [])
          .map(part => part.toString()),
        'list'
      ]
      .filter(part => '' !== part)
      .join('/');

    const referrerParts = (document.referrer || '').split('?');
    const listParams = (-1 !== referrerParts[0].indexOf(listUrl)) ? referrerParts[1] : '';

    window.location.href = `/${listUrl}?${listParams}`;
  }

  private findBaseRoute(route: ActivatedRoute): ActivatedRoute {
    if (route.parent === null) {
      return null;
    }

    if (
      route.snapshot.routeConfig.data &&
      true === route.snapshot.routeConfig.data.base
    ) {
      return route;
    }

    return this.findBaseRoute(route.parent);
  }

}

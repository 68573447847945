export class PaginationList<T> {
  public first: number;

  constructor(
    public items: Array<T> = [],
    public page: number = 1,
    public totalItems: number = 0,
    public limit: number = 0
  ) {
    this.first = (this.page - 1) * this.limit;
  }

}

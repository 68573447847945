import { Component, OnInit, ViewChild, ElementRef, Sanitizer, SecurityContext } from '@angular/core';

import { ModalComponent } from '../modal-component';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends ModalComponent<void> implements OnInit {

  public message: string;
  public title: string;

  @ViewChild('closeButton')
  public closeButton: ElementRef;
  private lastActiveElement: any;

  constructor(
    private sanitizer: Sanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.lastActiveElement = document.activeElement;

    setTimeout(() => {
      this.closeButton.nativeElement.focus();
    });
  }

  public setMessage(message: string): void {
    this.message = this.sanitizer.sanitize(SecurityContext.HTML, message);
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public close() {
    super.close();

    if (this.lastActiveElement) {
      this.lastActiveElement.focus();
    }
  }

}

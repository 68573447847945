import { Pipe, PipeTransform } from '@angular/core';

import { PurchaseProject } from '../../purchase-project/purchase-project.model';

@Pipe({
  name: 'currency'
})

export class CurrencyPipe implements PipeTransform {

  transform(value: any, project: PurchaseProject): any {

    if (project instanceof PurchaseProject) {
      return value + ' ' + project.currency;
    }

    return value;
  }
}

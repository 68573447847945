import { Injectable } from '@angular/core';
import { AuthHttpClient } from '@dvs-angular/auth-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Language } from './language.model';

@Injectable()
export class LanguageRepository {

  constructor(
    private http: AuthHttpClient
  ) { }

  public getLanguages(): Observable<Language[]> {
    return this.http.get(environment.api.shoppingpanel + '/language')
        .pipe(
          map(result => result instanceof Array ? result : []),
          map(result => result.map(
            languageData => new Language(languageData.id, languageData.name)
          ))
        );
  }

}

import { Directive, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { PermissionService } from './permission.service';

@Directive({
  selector: '[roleClass]'
})
export class RoleClassDirective implements OnInit, OnDestroy {

  @Input('roleClass')
  public classes: { [className: string]: string | string[] };
  private permissionChangeSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private permissionService: PermissionService
  ) {
    this.permissionChangeSubscription = this.permissionService.onPermissionChange
      .subscribe(() => {
        this.updateClasses();
      });
  }

  ngOnInit() {
    this.updateClasses();
  }

  ngOnDestroy() {
    this.permissionChangeSubscription.unsubscribe();
  }

  private updateClasses() {
    for (let className in this.classes) {
      if (!this.classes.hasOwnProperty(className)) {
        continue;
      }

      let role: string | string[] = this.classes[className];

      this.permissionService
      .hasPermission(role)
      .subscribe((hasPermission: boolean) => {
        if (hasPermission) {
          this.addClass(className);

          return;
        }

        this.removeClass(className);
      });
    }
  }

  private addClass(className: string) {
    let classNamesArray: string[] = className.split(' ');

    for (let item of classNamesArray) {
      this.elementRef.nativeElement.classList.add(item);
    }
  }

  private removeClass(className: string) {
    let classNamesArray: string[] = className.split(' ');

    for (let item of classNamesArray) {
      this.elementRef.nativeElement.classList.remove(item);
    }
  }

}

import { AuthHttpClient, Token } from '@dvs-angular/auth-http-client';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from './user.model';

@Injectable()
export class UserRepository {

  constructor(
    private http: HttpClient,
    private authHttp: AuthHttpClient
  ) { }

  public login(username: string, password: string): Observable<Token> {
    return this.http.post(
        environment.api.shoppingpanel + '/login',
        { username, password }
      )
      .pipe(
        map((responseJson: any): Token =>
          new Token(
            responseJson.token,
            responseJson.refresh_token
          )
        )
      );
  }

  public selfInfo(): Observable<any> {
    return this.authHttp.get(
        environment.api.shoppingpanel + '/user/me',
      );
  }

}

import { Directive, Input, Inject, HostListener } from '@angular/core';
import { PlatformLocation } from '@angular/common';

@Directive({
  selector: '[sonataLink]'
})
export class SonataLinkDirective {

  @Input('sonataLink')
  private sonataLink: string;
  private baseHref: string;

  constructor(
    platformLocation: PlatformLocation
  ) {
    this.baseHref = platformLocation.getBaseHrefFromDOM()
      .replace(/\/*$/, '/'); // replace any number of ending '/' with exactly one '/'
  }

  @HostListener('click')
  public followLink(link: string = this.sonataLink) {
    const normalizedLink = link.replace(/^\/*/, '');
    window.location.href = this.baseHref + normalizedLink;
  }

}

import { Directive, Input, OnInit, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';

import { PermissionService } from './permission.service';

@Directive({
  selector: '[ifRole]',
})
export class IfRoleDirective implements OnInit, OnDestroy {

  @Input('ifRole')
  public requiredRole: string | string[];
  private isVisible: boolean = false;
  private permissionChangeSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private permissionService: PermissionService
  ) {
    this.permissionChangeSubscription = this.permissionService.onPermissionChange
      .subscribe(() => {
        this.updateVisibility();
      });
  }

  ngOnInit() {
    this.updateVisibility();
  }

  ngOnDestroy() {
    this.permissionChangeSubscription.unsubscribe();
  }

  private updateVisibility() {
    this.permissionService.hasPermission(this.requiredRole)
    .subscribe((show: boolean) => {
      if (show === this.isVisible) {
        return;
      }

      if (show) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }

      this.isVisible = show;
    });
  }

}

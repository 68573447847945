import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-lock',
  templateUrl: './ui-lock.component.html',
  styleUrls: ['./ui-lock.component.scss']
})
export class UiLockComponent {

  @Input('visibility')
  public visibility: boolean;

  constructor() { }

}

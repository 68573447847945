import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @ViewChild('notifyContainer', { read: ElementRef })
  public notifyContainer: ElementRef;
  @ViewChild('pageLoader', { read: ElementRef })
  public pageLoader: ElementRef;

  ngAfterViewInit() {
    // sonata layout fix
    const body = document.querySelector('body');

    body.appendChild(this.notifyContainer.nativeElement);
    body.appendChild(this.pageLoader.nativeElement);
  }

}

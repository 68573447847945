export class UploaderOptions {

  public auth: boolean = true;
  public multiple: boolean = false;
  public title: string = '';
  public accept: string = '';
  private _name: string = 'file';

  constructor(
    public uploadUrl: string
  ) {}

  public set name(name) {
    this._name = name;

    if (this._name.indexOf('[]') > -1) {
      this.multiple = true;
    }
  }
  public get name(): string {
    return this._name;
  }

}

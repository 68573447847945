import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PermissionService } from './permission.service';
import { IfRoleDirective } from './if-role.directive';
import { RoleClassDirective } from './role-class.directive';

@NgModule({
  declarations: [
    IfRoleDirective,
    RoleClassDirective
  ],
  exports: [
    IfRoleDirective,
    RoleClassDirective
  ]
})
export class PermissionModule {

  static forRoot() {
    return {
      ngModule: PermissionModule,
      providers: [PermissionService]
    };
  }

  static forChild() {
    return {
        ngModule: PermissionModule
    };
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MaxLengthCounterComponent } from './max-length-counter.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  declarations: [
    MaxLengthCounterComponent
  ],
  exports: [
    MaxLengthCounterComponent
  ]
})
export class MaxLengthCounterModule { }

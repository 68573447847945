import { Observable, of, forkJoin } from 'rxjs';

export class RoleDefinition {

  private testFunctions: Array<() => boolean | Observable<boolean> | Promise<boolean>> = [];

  constructor(
    private roleName: string,
    testFunction: () => boolean | Observable<boolean> | Promise<boolean>,
    private inheritedRoles: string[] = []
  ) {
    this.addTest(testFunction);
  }

  public getRoleName(): string {
    return this.roleName;
  }

  public addTest(testFunction: () => boolean | Observable<boolean> | Promise<boolean>) {
    this.testFunctions.push(testFunction);
  }

  public addInheritedRoles(roles: string[]) {
    for (let role of roles) {
      if (this.inheritedRoles.indexOf(role) !== -1) {
        this.inheritedRoles.push(role);
      }
    }
  }

  public getInheritedRoles(): string[] {
    return this.inheritedRoles;
  }

  public mergeTests(): Observable<boolean> {
    let testResults: any[] = this.testFunctions
      .map((test): Observable<boolean> | Promise<boolean> => {
        let testResult = test.call(this);

        if ('boolean' === typeof testResult) {
          return of(testResult);
        }

        return testResult;
      });

    testResults.push((...testsResults: boolean[]): boolean =>
      testsResults.reduce((prev: boolean, current: boolean): boolean => prev && current)
    );

    return forkJoin.apply(this, testResults);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/primeng';

import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LanguageRepository } from './language.repository';
import { LanguageService } from './language.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TranslateModule.forChild()
  ],
  declarations: [
    LanguageSelectorComponent
  ],
  exports: [
    LanguageSelectorComponent
  ]
})
export class LanguageModule {

  static forRoot() {
    return {
      ngModule: LanguageModule,
      providers: [
        LanguageRepository,
        LanguageService
      ]
    };
  }

  static forChild() {
    return {
        ngModule: LanguageModule
    };
  }
}

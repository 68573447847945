import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RoleDefinition } from './role-definition';

function DEFAULT_ROLE_TEST() {
  return false;
}

export type RedirectTo = { state: string, queryParams?: any };

@Injectable()
export class PermissionService implements CanActivate, CanLoad {

  public onPermissionChange: EventEmitter<any> = new EventEmitter();
  private definitions: RoleDefinition[] = [];
  private rolesInheritance: { [roleName: string]: string[]} = {};

  constructor(
    private router: Router
  ) { }

  canLoad(route: Route): Observable<boolean> {
    return this.resolveRoute(route);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.resolveRoute(route);
  }

  public defineRole(
    role: string | string[],
    testFunction: () => boolean | Observable<boolean> | Promise<boolean> = DEFAULT_ROLE_TEST,
    inheritedRoles: string[] = []
  ): void {
    let rolesNamesArray: string[] = this.forceArray(role);
    inheritedRoles = this.removeDuplicatedRoles(inheritedRoles);

    for (let roleName of rolesNamesArray) {
      this.addRoleDefinition(roleName, testFunction, inheritedRoles);
    }

    this.updateInheritances();
  }

  public permissionChanged(): void {
    this.onPermissionChange.emit();
  }

  public hasPermission(requestedRole: string | string[]): Observable<boolean> {
    let requestedRoleArray: string[] = this.forceArray(requestedRole);

    for (let roleName of requestedRoleArray) {
      requestedRoleArray = requestedRoleArray.concat(this.getKnownInherits(roleName));
    }

    requestedRoleArray = this.removeDuplicatedRoles(requestedRoleArray);

    let rolesResults: any[] = this.definitions
      .filter(
        (definition: RoleDefinition): boolean =>
          requestedRoleArray.indexOf(definition.getRoleName()) >= 0
      )
      .map(
        (definition: RoleDefinition): Observable<boolean> =>
          definition.mergeTests()
      );

    if (0 === rolesResults.length) {
      return of(false);
    }

    rolesResults.push((...results: boolean[]): boolean =>
      results.reduce((prev: boolean, current: boolean): boolean => prev || current)
    );

    return forkJoin.apply(this, rolesResults);
  }

  public getRoleDefinition(roleName: string): RoleDefinition {
    for (let existingDefinition of this.definitions) {
      if (roleName === existingDefinition.getRoleName()) {
        return existingDefinition;
      }
    }

    return null;
  }

  private resolveRoute(route: ActivatedRouteSnapshot | Route): Observable<boolean> {
    if (
      undefined === route.data ||
      undefined === route.data['only']
    ) {
      return of(true);
    }

    let routeRoles: string | string[] = route.data['only'] as (string | string[]);

    return this.hasPermission(routeRoles)
      .pipe(
        tap((canActivate: boolean) => {
          if (canActivate) {
            return;
          }

          const newRoute = this.getNewRoute(route.data['redirectTo'], route);

          if (null === newRoute) {
            return;
          }

          this.router.navigate([newRoute.state], {queryParams: newRoute.queryParams});
        })
      );
  }

  private getNewRoute(
    newRoute: RedirectTo | ((deniedRoute: ActivatedRouteSnapshot | Route) => string | RedirectTo),
    deniedRoute: ActivatedRouteSnapshot | Route
  ): RedirectTo {
    const redirectTarget = 'function' === typeof newRoute ?
      newRoute(deniedRoute) :
      newRoute;

    if (this.isRedirectToObject(redirectTarget)) {
      return redirectTarget as RedirectTo;
    }

    if ('string' === typeof redirectTarget) {
      return { state: redirectTarget };
    }

    return null;
  }

  private isRedirectToObject(input: any): boolean {
    return (
        'object' === typeof input &&
        null !== input &&
        input.hasOwnProperty('state')
      );
  }

  private addRoleDefinition(
    roleName: string,
    testFunction: () => boolean | Observable<boolean> | Promise<boolean>,
    inheritedRoles: string[]
  ) {
    for (let existingDefinition of this.definitions) {
      if (roleName === existingDefinition.getRoleName()) {
        existingDefinition.addTest(testFunction);
        existingDefinition.addInheritedRoles(inheritedRoles);

        return;
      }
    }

    this.definitions.push(new RoleDefinition(roleName, testFunction, inheritedRoles));
  }

  public getKnownInherits(roleName: string): string[] {
    if (this.rolesInheritance.hasOwnProperty(roleName)) {
      return this.rolesInheritance[roleName];
    }

    return [];
  }

  private updateInheritances() {
    this.rolesInheritance = {};

    for (let definition of this.definitions) {
      this.propagateInheritance(definition.getRoleName(), definition.getInheritedRoles());
    }

    for (let roleName in this.rolesInheritance) {
      if (!this.rolesInheritance.hasOwnProperty(roleName)) {
        continue;
      }

      this.rolesInheritance[roleName] = this.removeDuplicatedRoles(this.rolesInheritance[roleName]);
    }
  }

  private propagateInheritance(inheritedRoleName: string, parentRolesNames: string[]) {
    let parentDefinitions: RoleDefinition[] = parentRolesNames
      .map((parentRoleName: string): RoleDefinition => this.getRoleDefinition(parentRoleName))
      .filter((roleDefinition: RoleDefinition): boolean => roleDefinition !== null);

    for (let definition of parentDefinitions) {
      let definitionRoleName: string = definition.getRoleName();

      if (!this.rolesInheritance.hasOwnProperty(definitionRoleName)) {
        this.rolesInheritance[definitionRoleName] = [];
      }

      this.rolesInheritance[definitionRoleName].push(inheritedRoleName);
      this.propagateInheritance(inheritedRoleName, definition.getInheritedRoles());
    }
  }

  private forceArray(role: string | string[]): string[] {
    if (role instanceof Array) {

      return role;
    }

    return [role];
  }

  private removeDuplicatedRoles(roles: string[]): string[] {
    let uniqueRoles: string[] = [];

    for (let role of roles) {
      if (uniqueRoles.indexOf(role) === -1) {
        uniqueRoles.push(role);
      }
    }

    return uniqueRoles;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ValidationErrorComponent } from './validation-error.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  declarations: [
    ValidationErrorComponent
  ],
  exports: [
    ValidationErrorComponent
  ]
})
export class ValidationErrorModule { }

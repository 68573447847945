import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Currency } from './currency.model';
import { CurrencyRepository } from './currency.repository';
import { shareReplay, take, map } from 'rxjs/operators';

@Injectable()
export class CurrencyService {

  private request: Observable<Currency[]>;

  constructor(
    currencyRepository: CurrencyRepository
  ) {
    this.request = currencyRepository.list()
      .pipe(
        shareReplay(1)
      );

    this.loadCurrencies();
  }

  public getById(id: string): Observable<Currency> {
    const serializedId = id.toLocaleLowerCase();

    return this.request
      .pipe(
        take(1),
        map(
          currencies => currencies
            .find(currency => currency.matchId(serializedId))
        )
      );
  }

  public query(query: string, limit: number): Observable<Currency[]> {
    const serializedQuery = query.toLocaleLowerCase();

    return this.request
      .pipe(
        take(1),
        map(
          currencies => currencies
            .filter(currency => currency.match(serializedQuery))
            .slice(0, limit)
        )
      );
  }

  private loadCurrencies() {
    this.request
    .pipe(
      take(1)
    )
    // dummy subscription
    .subscribe(
      () => { },
      () => { }
    );
  }

}
